import React, { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from './AuthContext';

const ProtectedRoute = ({ children }) => {
  const { isAuthenticated, loading, profile, status, currentUser } = useAuth();
  const location = useLocation();
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    if (!loading && status !== 'loading' && 
        ((isAuthenticated && profile) || 
         (!isAuthenticated && status === 'unauthenticated'))) { 
      setIsReady(true);
    }
  }, [loading, status, isAuthenticated, profile]);

  if (!isReady) {
    return <div>Loading...</div>;
  }

  if (!isAuthenticated && status === 'unauthenticated') {
    return <Navigate to="/login" state={{ from: location.pathname }} replace />;
  }

  if (isAuthenticated && 
      currentUser && 
      !profile && 
      status === 'authenticated' && 
      location.pathname !== '/create-profile') {
    return <Navigate to="/create-profile" state={{ from: location.pathname }} replace />;
  }

  return children;
};

export default ProtectedRoute;