import React, { useEffect, Suspense, lazy } from 'react';
import './App.css';
import './Pages.css';
import './Components.css';
import { useLoadScript } from '@react-google-maps/api';
import { Routes, Route } from 'react-router-dom';
import { OrderProvider } from './contexts/OrderContext';
import Layout from './contexts/PageWrapper';
import ProtectedRoute from './contexts/ProtectedRoute';
import Browse from './pages/Browse';
import useTabVisibility from './contexts/useTabVisibility';

const About = lazy(() => import('./pages/About'));
const AccountDetails = lazy(() => import('./pages/AccountDetails'));
const AddService = lazy(() => import('./pages/AddService'));
const ChatPageWindow = lazy(() => import('./pages/ChatPageWindow'));
const CommunityStandards = lazy(() => import('./pages/CommunityStandards'));
const EditService = lazy(() => import('./pages/EditService'));
const Feedback = lazy(() => import('./pages/Feedback'));
const ForgotPassword = lazy(() => import('./pages/ForgotPassword'));
const FrequentlyAsked = lazy(() => import('./pages/FrequentlyAsked'));
const GoogleUsers = lazy(() => import ('./pages/GoogleUsers'));
const Home = lazy(() => import('./pages/Home'));
const HowItWorks = lazy(() => import('./pages/HowItWorks'));
const HowItWorksSeller = lazy(() => import('./pages/HowItWorksSeller'));
const Login = lazy(() => import('./pages/Login'));
const MyOrders = lazy(() => import('./pages/MyOrders'));
const MyJobs = lazy(() => import('./pages/MyJobs'));
const NotFound = lazy(() => import('./pages/NotFound'));
const OrderSummary = lazy(() => import('./pages/OrderSummary'));
const PaymentResult = lazy(() => import('./pages/PaymentResult'));
const Payment = lazy(() => import('./pages/Payment'));
const PhotoUploadEdit = lazy(() => import('./pages/PhotoUploadEdit'));
const PrivacyPolicy = lazy(() => import('./pages/PrivacyPolicy'));
const Products = lazy(() => import('./pages/Products'));
const Profile = lazy(() => import('./pages/Profile'));
const Refresh = lazy(() => import('./components/Stripe/Refresh'));
const ResetPassword = lazy(() => import('./pages/ResetPassword'));
const Return = lazy(() => import('./components/Stripe/Return'));
const SellerRegistration = lazy(() => import ('./pages/SellerRegistration'));
const ServiceDash = lazy(() => import('./pages/ServiceDash'));
const ServiceSummary = lazy(() => import('./pages/ServiceSummary'));
const ServiceReviews = lazy(() => import('./pages/ServiceReviews'));
const SignUp = lazy(() => import('./pages/SignUp'));
const StoreName = lazy(() => import('./pages/StoreName'));
const StripeWrapper = lazy(() => import('./components/StripeWrapper'));
const SubmitReview = lazy(() => import('./pages/SubmitReview'));
const TermsOfService = lazy(() => import('./pages/TermsOfService'));
const TypesOfJobs = lazy(() => import('./pages/TypesOfJobs'));
const Updates = lazy(() => import('./pages/Updates'));
const UserProfile = lazy(() => import('./pages/UserProfile'));
const VerifyEmail = lazy(() => import('./pages/VerifyEmail'));
const VerifyPhone = lazy(() => import('./pages/VerifyPhone'));
const Welcome = lazy(() => import('./pages/Welcome'));
const WhatsNext = lazy(() => import('./pages/WhatsNext'));
const WelcomeSeller = lazy(() => import('./pages/WelcomeSeller'));

function App() {
  useEffect(() => {
    document.cookie = "GOOGAPPUID=staging; path=/";
  }, []);

  useTabVisibility();
    const { isLoaded, loadError } = useLoadScript({
      googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY, 
      libraries: ['places'], 
    });
  
    if (loadError) {
      return <div>Error loading Google Maps. Please try again later.</div>;
    }

  return (
    <Layout>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          {/* Public Routes */}
          <Route path="/" element={<Home />} />
          <Route path="/about-quic" element={<About />} />
          <Route path="/booking-summary/:id" element={<OrderSummary />} />
          <Route path="/browse" element={<Browse isMapLoaded={isLoaded} />} />
          <Route path="/community-guidelines" element={<CommunityStandards />} />
          <Route path="/create-profile" element={<GoogleUsers />} />
          <Route path="/feedback-form" element={<Feedback />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/frequently-asked-questions" element={<FrequentlyAsked />} />
          <Route path="/how-it-works" element={<HowItWorks />} />
          <Route path="/how-it-works-seller" element={<HowItWorksSeller />} />
          <Route path="/listing-guide" element={<WelcomeSeller />} />
          <Route path="/login" element={<Login />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/products/:slug/:id" element={<OrderProvider><Products /></OrderProvider>} />
          <Route path="/quic-updates" element={<Updates />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/terms-of-services" element={<TermsOfService />} />
          <Route path="/types-of-services" element={<TypesOfJobs />} />
          <Route path="/reset-password/:token" element={<ResetPassword />} />
          <Route path="/view-user-profile/:username/:userId" element={<UserProfile />} />
          <Route path="/welcome" element={<Welcome />} />
          <Route path="/whats-next" element={<WhatsNext />} />
          <Route path="/verify-email" element={<VerifyEmail />} />

          {/* Protected Routes */}
          <Route path="/add-service" element={<ProtectedRoute><AddService /></ProtectedRoute>} />
          <Route path="/dashboard" element={<ProtectedRoute><ServiceDash /></ProtectedRoute>} />
          <Route path="/edit-service" element={<ProtectedRoute><EditService /></ProtectedRoute>} />
          <Route path="/profile" element={<ProtectedRoute><Profile /></ProtectedRoute>} />
          <Route path="/messages" element={<ProtectedRoute><ChatPageWindow /></ProtectedRoute>} />
          <Route path="/messages/:conversationId" element={<ProtectedRoute><ChatPageWindow /></ProtectedRoute>} />
          <Route path="/my-jobs" element={<ProtectedRoute><MyJobs /></ProtectedRoute>} />
          <Route path="/photo-upload/:serviceId" element={<ProtectedRoute><PhotoUploadEdit /></ProtectedRoute>} />
          <Route path="/seller-registration" element={<ProtectedRoute><SellerRegistration /></ProtectedRoute>} />
          <Route path="/save-name" element={<ProtectedRoute><StoreName /></ProtectedRoute>} />
          <Route path="/service-summary" element={<ProtectedRoute><ServiceSummary /></ProtectedRoute>} />
          <Route path="/service-reviews" element={<ProtectedRoute><ServiceReviews /></ProtectedRoute>} />
          <Route path="/submit-review" element={<ProtectedRoute><SubmitReview /></ProtectedRoute>} />
          <Route path="/my-orders" element={<ProtectedRoute><MyOrders /></ProtectedRoute>} />
          <Route path="/verify-phone" element={<ProtectedRoute><VerifyPhone /></ProtectedRoute>} />
          
          {/* Stripe-related Protected Routes */}
          {/* <Route
            path="/create-stripe-account"
            element={
              <ProtectedRoute>
                <Elements stripe={stripePromise}>
                  <CreateStripeAccount />
                </Elements>
              </ProtectedRoute>
            }
          /> */}
          <Route
            path="/stripe/refresh/:connectedAccountId"
            element={
              <ProtectedRoute>
                <StripeWrapper>
                  <Refresh />
                </StripeWrapper>
              </ProtectedRoute>
            }
          />
          <Route
            path="/return/:connectedAccountId"
            element={
              <ProtectedRoute>
                <StripeWrapper>
                  <Return />
                </StripeWrapper>
              </ProtectedRoute>
            }
          />
          <Route
            path="/payment"
            element={
              <ProtectedRoute>
                <StripeWrapper>
                  <Payment />
                </StripeWrapper>
              </ProtectedRoute>
            }
          />
          <Route
            path="/payment-result"
            element={
              <ProtectedRoute>
                <StripeWrapper>
                  <PaymentResult />
                </StripeWrapper>
              </ProtectedRoute>
            }
          />
          <Route
            path="/my-account-details"
            element={
              <ProtectedRoute>
                <StripeWrapper>
                  <AccountDetails />
                </StripeWrapper>
              </ProtectedRoute>
            }
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>
    </Layout>
  );
}

export default App;