import React, { createContext, useState, useEffect, useContext, useCallback, useRef } from 'react';
import axios from 'axios';
import config from './config';
import { supabase, getAccessToken, isTokenExpired, refreshSession } from './supabaseClient';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [profile, setProfile] = useState(null);
  const [session, setSession] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [status, setStatus] = useState('loading');
  const [token, setToken] = useState(null);

  const profileFetchedRef = useRef(false);

  const updateAuthState = useCallback((user, newSession) => {
    setCurrentUser(user);
    setSession(newSession);
    setToken(newSession?.access_token || null);
    setIsAuthenticated(!!user);
    setLoading(false);
    setStatus(user ? 'authenticated' : 'unauthenticated');
  }, []);

  const logout = useCallback(async () => {
    try {
      await supabase.auth.signOut();
      updateAuthState(null, null);
      setProfile(null);
      setStatus('unauthenticated');
      profileFetchedRef.current = false; 
    } catch (error) {
      console.error('Logout error:', error);
    }
  }, [updateAuthState]);

  const refreshToken = useCallback(async () => {
    if (!session) {
      console.log('No session to refresh');
      return null;
    }
    try {
      const { data, error } = await refreshSession();
      if (error) {
        console.error('Token refresh failed:', error);
        if (error.message === 'Auth session missing!') {
          await logout();
        }
        return null;
      }
      if (data.session) {
        updateAuthState(data.session.user, data.session);
        return data.session.access_token;
      }
      return null;
    } catch (err) {
      console.error('Failed to refresh session:', err);
      await logout();
      return null;
    }
  }, [session, logout, updateAuthState]);

  const getValidToken = useCallback(async () => {
    if (session?.access_token && !isTokenExpired(session.access_token)) {
      return session.access_token;
    }
    return refreshToken();
  }, [refreshToken, session]);

  const fetchUserProfile = useCallback(async () => {
    if (!currentUser) return;
  
    try {
      const token = await getValidToken();
      if (!token) {
        console.log('No valid token available');
        return;
      }
      const response = await axios.get(`${config.API_URL}/auth/profile`, {
        headers: { Authorization: `Bearer ${token}` },
      });
  
      setProfile(response.data);
      profileFetchedRef.current = true; 
    } catch (error) {
      console.error('Error fetching profile:', error);
    }
  }, [currentUser, getValidToken]);

  const login = async (email, password) => {
    console.log('Logging in');
    try {
      const { data, error } = await supabase.auth.signInWithPassword({ email, password });
      if (error) throw error;

      updateAuthState(data.user, data.session);
      profileFetchedRef.current = false;
      return data;
    } catch (error) {
      console.error('Login error:', error);
      throw error;
    }
  };

  const manualRefresh = async () => {
    setLoading(true);
    const newToken = await refreshToken();
    if (newToken) {
      profileFetchedRef.current = false; // Reset profile fetch
      await fetchUserProfile();
    }
    setLoading(false);
  };

  useEffect(() => {
    const { data: authListener } = supabase.auth.onAuthStateChange(async (event, session) => {
      try {
        setLoading(true);
        if (session?.user) {
          updateAuthState(session.user, session);
          profileFetchedRef.current = false; // Reset profile fetch when session changes
        } else {
          updateAuthState(null, null);
          setProfile(null);
          profileFetchedRef.current = false;
        }
      } catch (error) {
        console.error('Error in onAuthStateChange:', error);
        setStatus('unauthenticated');
      } finally {
        setLoading(false);
      }
    });

    return () => {
      authListener.subscription.unsubscribe();
    };
  }, [updateAuthState]);

  useEffect(() => {
    const restoreSession = async () => {
      console.log('Restoring session');
      try {
        const { data: sessionData, error } = await supabase.auth.getSession();
        if (error) {
          console.error('Error getting session:', error);
          setStatus('unauthenticated');
        } else if (sessionData?.session) {
          updateAuthState(sessionData.session.user, sessionData.session);
          profileFetchedRef.current = false;
        } else {
          console.log('No session found');
          setStatus('unauthenticated');
        }
      } catch (error) {
        console.error('Error restoring session:', error);
        setStatus('unauthenticated');
      } finally {
        setLoading(false);
      }
    };
    restoreSession();
  }, [updateAuthState]);

  useEffect(() => {
    if (currentUser && !profile && !profileFetchedRef.current) {
      fetchUserProfile();
    }
  }, [currentUser, profile, fetchUserProfile]);

  useEffect(() => {
    const interceptor = axios.interceptors.request.use(
      async (config) => {
        const token = await getValidToken();
        if (token) {
          config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    const responseInterceptor = axios.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalRequest = error.config;
        if (error.response.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true;
          const token = await refreshToken();
          if (token) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            return axios(originalRequest);
          }
        }
        return Promise.reject(error);
      }
    );

    return () => {
      axios.interceptors.request.eject(interceptor);
      axios.interceptors.response.eject(responseInterceptor);
    };
  }, [getValidToken, refreshToken]);

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        userId: currentUser?.id,
        profile,
        loading,
        session,
        token,
        isAuthenticated,
        status, 
        login,
        logout,
        fetchUserProfile,
        refreshToken,
        getValidToken,
        manualRefresh,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);