import { createClient } from '@supabase/supabase-js';

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseKey = process.env.REACT_APP_SUPABASE_ANON_KEY;

if (!supabaseUrl || !supabaseKey) {
  throw new Error('Supabase URL or Key is missing. Please check your environment variables.');
}

export const supabase = createClient(supabaseUrl, supabaseKey, {
  auth: {
    autoRefreshToken: true,
    persistSession: true,
    detectSessionInUrl: true
  }
});

export const signIn = async (email, password) => {
  const { data, error } = await supabase.auth.signInWithPassword({ email, password });
  if (error) throw error;
  return data;
};

export const signUp = async (email, password) => {
  const { data, error } = await supabase.auth.signUp({ email, password });
  if (error) throw error;
  return data;
};

export const signOut = async () => {
  const { error } = await supabase.auth.signOut();
  if (error) throw error;
};

export const resetPassword = async (email) => {
  const { error } = await supabase.auth.resetPasswordForEmail(email, {
      redirectTo: `${window.location.origin}/`, 
  });
  if (error) {
      throw error;
  }
};

export const getCurrentSession = async () => {
  const { data, error } = await supabase.auth.getSession();
  if (error) throw error;
  return data.session;
};

export const getCurrentUser = async () => {
  const { data, error } = await supabase.auth.getUser();
  if (error) {
    console.error('Error fetching user:', error);
    return null;
  }
  return data.user;
};

export const isTokenExpired = (token) => {
  if (!token) return true;
  try {
    const payload = JSON.parse(atob(token.split('.')[1]));
    return payload.exp * 1000 < Date.now();
  } catch (error) {
    console.error('Error parsing token:', error);
    return true;
  }
};

export const getAccessToken = async () => {
  try {
    const { data: { session } } = await supabase.auth.getSession();
    if (!session) {
      console.log('No active session');
      return null;
    }
    if (session?.access_token && !isTokenExpired(session.access_token)) {
      return session.access_token;
    }
    console.log('Token is expired or invalid.');
    return null;
  } catch (error) {
    console.error('Error accessing token:', error);
    return null;
  }
};

export const refreshSession = async () => {
  try {
    const { data: { session } } = await supabase.auth.getSession();
    if (!session) {
      return { data: null, error: new Error('No active session') };
    }
    const { data, error } = await supabase.auth.refreshSession();
    if (error) throw error;
    return { data, error: null };
  } catch (error) {
    console.error('Error refreshing session:', error);
    return { data: null, error };
  }
};

const checkTable = async (tableName) => {
  try {
    const { count, error } = await supabase
      .from(tableName)
      .select('count', { count: 'exact', head: true });

    if (error) {
      console.error(`Error checking ${tableName} table:`, error);
    } else {
      console.log(`${tableName} table exists. Row count:`, count);
    }
  } catch (error) {
    console.error(`Failed to check ${tableName} table:`, error);
  }
};

if (process.env.NODE_ENV !== 'production') {
  Promise.all([checkTable('conversations'), checkTable('messages')])
    .then(() => console.log('Database check complete'))
    .catch(error => console.error('Error during database check:', error));
}


export const resendVerificationEmail = async (email) => {
  try {
    const response = await fetch('/auth/resend-verification-email', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    });

    const data = await response.json();
    if (!response.ok) {
      console.error('Failed to resend verification email:', data.error);
      throw new Error(data.error || 'An unexpected error occurred.');
    }

    console.log('Verification email resent successfully.');
    return data;
  } catch (error) {
    console.error('Error resending verification email:', error.message);
    throw error;
  }
};